<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card card-warning card-outline">
                <div class="card-header">
                    <div class="card-title">
                        <h4><i class="fa fa-briefcase"></i> Bestellungen</h4>
                        <p>Archivierte Bestellungen</p>
                    </div>

                    <div class="card-tools">
                        <button type="button" class="mr-1 btn btn-default btn-sm" @click="$parent.loadWholesaler">
                            <i class="fas fa-fw fa-sync"></i>
                        </button>
                        <button type="button" class="btn btn-primary btn-sm" v-if="$auth.check('ingredients.create')">
                            <i class="fas fa-fw fa-plus"></i>
                        </button>
                    </div>
                </div>

                <div class="card-body">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>